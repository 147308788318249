import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React, { memo, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import "./ReportPermissionsModal.scss";
import { Divider } from "@aws-amplify/ui-react";

const ReportPermissionsModal = ({
  closeModal = () => {},
  current = {},
  apiRef = null,
}) => {
  const dummyPermissions = Array.from({ length: 16 })?.map(
    (_value, index) => `Item ${index + 1}`
  );
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="report-permissions-modal">
      <h4>{current?.report_name}</h4>
      <div className="d-flex gap-5">
        <p className="profile_subsection-dim_title mb-0">
          User Type: {current?.user_type}
        </p>
        <p className="profile_subsection-dim_title mb-0">
          User Name: {current?.user_name}
        </p>
      </div>
      <div className="card">
        <p className="profile_subsection-dim_title mb-0">Yes Candidates</p>
        <FormGroup className="select-all-checkbox">
          <FormControlLabel
            className="w-fit"
            control={<Checkbox />}
            label="Select ALL"
          />
        </FormGroup>
        <FormGroup className="permissions-form">
          <Row>
            {dummyPermissions?.map((item) => (
              <Col key={item} lg={3}>
                <FormControlLabel control={<Checkbox />} label={item} />
              </Col>
            ))}
          </Row>
        </FormGroup>
        <Divider className="my-4" />
        <p className="profile_subsection-dim_title mb-0">No Candidates</p>
        <FormGroup className="select-all-checkbox">
          <FormControlLabel
            className="w-fit"
            control={<Checkbox />}
            label="Select ALL"
          />
        </FormGroup>
        <FormGroup className="permissions-form">
          <Row>
            {dummyPermissions?.map((item) => (
              <Col key={item} lg={3}>
                <FormControlLabel control={<Checkbox />} label={item} />
              </Col>
            ))}
          </Row>
        </FormGroup>
      </div>
      <div className="user-card-button">
        <button type="submit" className="action-button" disabled={isLoading}>
          {isLoading ? (
            <div>
              <Spinner
                animation="border"
                size="sm"
                className="text-white"
                style={{ width: "1rem" }}
              />
            </div>
          ) : (
            <div className="m-0">Save</div>
          )}
        </button>
        <button
          type="button"
          className="action-button-outline"
          onClick={closeModal}
        >
          <div className="m-0">Cancel</div>
        </button>
      </div>
    </div>
  );
};

export default memo(ReportPermissionsModal);
