import React, { useContext, useMemo, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Col, Dropdown, Nav, Navbar, Row } from "react-bootstrap";
import { MDBBadge } from "mdb-react-ui-kit";
import { useAuthenticator } from "@aws-amplify/ui-react";

import i18n from "../../i18n.js";
import "./Header.scss";
import { UserContext } from "context/UserContext";
import Logo from "../../assets/svg/logo.svg";
import BellSvg from "../../assets/svg/bell-notification.svg";
import ButtonUI from "components/UI/Button/ButtonUI";
import ModalBox from "components/Modal/ModalBox";
import SelectUI from "components/Select/SelectUI";
import { FilterContext } from "context/FilterContext";
import { useTranslation } from "react-i18next";

const ClientSelectModalContent = ({ handleCancel, handleConfirm }) => {
  const { dropdownData, Clients, setClients } = useContext(FilterContext);
  const [showValidationError, setShowValidationError] = useState("");

  const selectedFilterValue = (selectedValue) => {
    setClients(selectedValue);
  };

  const setValidationMsg = (msg) => {
    setShowValidationError(msg);
  };

  return (
    <form
      className="px-5 py-3"
      onSubmit={(e) => {
        e.preventDefault();
        handleConfirm(setValidationMsg);
      }}
    >
      <SelectUI
        placeholder={"Choose a Client"}
        options={dropdownData.ClientOptions}
        selectedValue={selectedFilterValue}
        isMulti={false}
        isClearable={true}
        value={Clients}
      />
      {showValidationError ? (
        <p className="text-danger p-0 m-0 fs-6">{showValidationError}</p>
      ) : (
        <></>
      )}

      <Row className="user-card-button justify-content-between ">
        <Col className=" col-content col-sm-0 flex-md-grow-1 ">
          <div
            className="action-button-outline w-100"
            aria-disabled={true}
            onClick={handleCancel}
          >
            <div className=" px-0 flex-grow-1 m-0">Cancel</div>
          </div>
        </Col>
        <Col className=" col-content col-sm-0 flex-md-grow-1">
          <button type="submit" className="action-button px-1 w-100">
            <div className=" px-0 flex-grow-1 m-0">Continue</div>
          </button>
        </Col>
      </Row>
    </form>
  );
};

const Header = () => {
  const { t } = useTranslation();
  const { signOut } = useAuthenticator((context) => [context.user]);

  const {
    language,
    setLanguage,
    loggedInUserData,
    clearUserContext,
    userGroup,
  } = useContext(UserContext);
  const {
    Clients,
    setIsPresentationEnabled,
    isPresentationEnabled,
    isInternalUser,
    setClients,
    resetFiltersBeforeAndAfterPresentation,
    clearFilterContext,
  } = useContext(FilterContext);
  const [btnToggleValue, setBtnToggleValue] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const navLinks = useMemo(
    () => [
      {
        label: t("dashboardText.dashboard"),
        to: "/",
        isDisplay: true,
      },
      {
        label: t("dashboardText.users"),
        to: "/users",
        isDisplay: isInternalUser,
      },
      {
        label: t("dashboardText.reports"),
        to: "/reports",
        isDisplay: true,
      },
      {
        label: t("dashboardText.jobs"),
        to: "/jobs",
        isDisplay: isInternalUser,
      },
      {
        label: t("dashboardText.jobs"),
        to: "/jobs",
        isDisplay: isInternalUser,
      },
      {
        label: t("dashboardText.candidates"),
        to: "/candidates",
        isDisplay: true,
      },
      {
        label: t("dashboardText.adminPanel"),
        to: "/adminpanel",
        isDisplay: loggedInUserData?.is_admin,
      },
    ],
    [t, isInternalUser, loggedInUserData?.is_admin]
  );

  const navigateToHomePage = () => {
    if (!["/"].includes(window.location.pathname)) {
      navigate("/", { replace: true });
    }
  };

  const notificationsList = [
    // { name: "New message received", isUnread: true },
    // { name: "Task completed", isUnread: true },
    // { name: "Upcoming event reminder", isUnread: false },
    // { name: "Last Report has been updated by mpt.", isUnread: false },
  ];

  const toggleLanguage = () => {
    let lng = language === "en" ? "ja" : "en";
    i18n.changeLanguage(lng);
    setLanguage(lng);
    localStorage.setItem("language", lng);
  };

  const handleSignOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    clearUserContext();
    clearFilterContext();
    window.history.replaceState({}, document.title);
    navigate("/");
    signOut();
  };

  const handleSettings = (linkParameter) => {
    navigate("/settings", {
      state: { routing: linkParameter },
    });
  };

  const handleShowModal = () => {
    setShowModal((current) => !current);
  };

  const handleStartPresentation = (setValidationMsg) => {
    if (Clients?.value) {
      sessionStorage.setItem("externalClient", JSON.stringify(Clients));
      resetFiltersBeforeAndAfterPresentation();
      setValidationMsg("");
      setBtnToggleValue(1);
      handleShowModal();
      navigateToHomePage();
      setIsPresentationEnabled(true);
    } else {
      setValidationMsg("Please, select client !");
    }
  };

  const handleStopPresentation = () => {
    sessionStorage.removeItem("externalClient");
    setBtnToggleValue(0);
    setIsPresentationEnabled(false);
    setClients([]);
    resetFiltersBeforeAndAfterPresentation();
    navigateToHomePage();
  };

  const handleCancelPresentation = () => {
    if (Clients?.value) {
      setClients([]);
      sessionStorage.removeItem("externalClient");
    }
    handleShowModal();
  };

  return (
    <Navbar bg="white" variant="white" expand="lg" className="navbar">
      <Navbar.Brand as={Link} to="/" className="logo-link">
        <img src={Logo} alt="Logo" className="logo-img" loading="lazy" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbar-nav" />

      <Navbar.Collapse id="navbar-nav" className="justify-content-between">
        <Nav className="ms-5">
          {navLinks?.map(
            (link) =>
              link?.isDisplay && (
                <NavLink key={link?.to} className="nav-link" to={link?.to}>
                  {link?.label}
                </NavLink>
              )
          )}
        </Nav>
        <div className="d-flex flex-md-nowrap flex-wrap gap-3 me-4 ms-5 ms-lg-0">
          {userGroup.includes("internal") && (
            <div className="align-self-center flex-shrink-0 mt-3 mt-sm-0 p-0">
              <ButtonUI
                outline={"no-outline"}
                title={
                  btnToggleValue === 0 && !isPresentationEnabled
                    ? "Start a New Presentation"
                    : "Stop Presentation"
                }
                onClick={() =>
                  btnToggleValue || isPresentationEnabled
                    ? handleStopPresentation()
                    : handleShowModal()
                }
              />
            </div>
          )}
          <div className="d-flex gap-1 flex-grow-1 gap-md-3 ">
            <Dropdown className="notify-class px-0">
              <Dropdown.Toggle
                className="d-flex bg-transparent border-0 align-items-center px-0"
                variant="light"
                id="user-dropdown"
              >
                <img src={BellSvg} alt="notification" />
                {notificationsList.length > 0 && (
                  <MDBBadge
                    color="danger"
                    notification
                    pill
                    className="p-1 mb-3 notification-badge"
                  >
                    {notificationsList.length}
                  </MDBBadge>
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu className="p-2">
                {notificationsList.map((notify, i) => (
                  <Dropdown.Item key={i} className="justify-content-between">
                    {notify.name}

                    {notify.isUnread && (
                      <div
                        style={{
                          color: "#f62e52",
                        }}
                      >
                        &bull;
                      </div>
                    )}
                  </Dropdown.Item>
                ))}
                <div className="d-flex">
                  <Dropdown.Item
                    className="notify-text justify-content-center border-0"
                    onClick={() => handleSettings("Notification")}
                  >
                    Notifications
                  </Dropdown.Item>
                  {/* <Dropdown.Item
                  className="notify-text justify-content-center border-0"
                  onClick={() => handleSettings("Preferences")}
                >
                  Preferences
                </Dropdown.Item> */}
                </div>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="header-profile px-0">
              <Dropdown.Toggle
                className="d-flex bg-transparent border-0 align-items-center gap-1 px-0"
                variant="light"
                id="user-dropdown"
              >
                {/* <div className="profile-image-container">
                <img
                  src=""
                  alt="Profile"
                  className="profile-image"
                />
              </div> */}
                <div className="profile-text">
                  {loggedInUserData?.email
                    ? `${loggedInUserData?.email.split("@")[0]} ${
                        loggedInUserData?.email.split("@")[1].split(".")[0]
                      }`
                    : "User name"}
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu align="right" className="p-2">
                <Dropdown.Item onClick={() => handleSettings("Profile")}>
                  Profile
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleSettings("Settings")}>
                  Settings
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleSignOut}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div
              className="form-control d-flex justify-content-end pe-0 p-0 border-0"
              style={{ backgroundColor: "transparent" }}
            >
              <div className="d-flex gap-2 align-items-center">
                <div className="language-toggel-switch">
                  <input
                    type="checkbox"
                    id="lol-checkbox"
                    checked={language === "en" ? true : false}
                    onChange={() => toggleLanguage()}
                    style={{ backgroundColor: "transparent" }}
                  />
                  <label id="switch-button" htmlFor="lol-checkbox">
                    <div id="switch-button-toogel"></div>
                    <div id="eng_lan">EN</div>
                    <div id="jp_lan">JP</div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Navbar.Collapse>

      <ModalBox
        customClass="special-modal"
        show={showModal}
        onHide={handleShowModal}
        hideCloseIcon={true}
        content={
          <ClientSelectModalContent
            handleCancel={handleCancelPresentation}
            handleConfirm={handleStartPresentation}
          />
        }
        title={<p className="fs-5 m-0 p-0">Select a Client to begin:</p>}
        size="md"
      />
    </Navbar>
  );
};

export default Header;
