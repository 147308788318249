import React, { memo, useState } from "react";
import UserAccountsFilter from "./UserAccountsFilter";
import "./UserAccounts.scss";
import {
  DataGridPro,
  getGridNumericOperators,
  getGridStringOperators,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import CustomFilter from "components/Reports/CustomFilter";
import { convertGridColumnMenu } from "helpers/ReusableFunctions";

import ButtonUI from "components/UI/Button/ButtonUI";
import ModalBox from "components/Modal/ModalBox";
import UserAccountModal from "./UserAccountModal";
import ConfirmModal from "./ConfirmModal";
import moment from "moment";

const UserAccounts = ({
  language,
  isInternalUser,
  isAdmin,
  isFetchingUser,
  users = [],
}) => {
  const apiRef = useGridApiRef();

  const sortableColumn = true;
  const pinnableColumn = true;

  const filterOperators = getGridStringOperators().filter(
    ({ value }) => !["isEmpty", "isNotEmpty", "isAnyOf"].includes(value)
  );

  const columns = [
    {
      field: "first_name",
      headerName: "User Name",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      valueGetter: ({ row }) => `${row?.first_name} ${row?.last_name}`,
    },
    {
      field: "is_admin",
      headerName: "User Type",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      valueGetter: ({ value }) => (value ? "Admin" : "Recruiter"),
    },
    {
      field: "confirmation_status",
      headerName: "Account Status",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "created",
      headerName: "Account Creation Date (MM/DD/YY)",
      width: 300,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      valueGetter: ({ value }) => moment(value).format("MM/DD/YY"),
    },
    {
      field: "last_login",
      headerName: "Last Login (MM/DD/YY)",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      valueGetter: ({ value }) => moment(value).format("MM/DD/YY"),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 400,
      renderCell: ({ row }) => (
        <div className="d-flex justify-content-center gap-3">
          <ButtonUI
            outline="no-outline"
            title="Edit User"
            onClick={() => {
              setSelectedRow(row);
              setIsEdit(true);
              handleShowDetailsModal();
            }}
          />
          <ButtonUI
            outline="no-outline "
            title="Reset Password"
            onClick={() => {
              setSelectedRow(row);
              handleShowConfirmModal(true);
            }}
          />
          <ButtonUI
            outline="no-outline "
            title="Revoke Access"
            onClick={() => {
              setSelectedRow(row);
              handleShowConfirmModal(false);
            }}
          />
        </div>
      ),
    },
  ];

  const [columnsOrder, setColumnsOrder] = useState(columns);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const columnsWithOperators = columnsOrder.map((col) => {
    if (col.type === "number") {
      return {
        ...col,
        filterOperators: [
          ...getGridNumericOperators()
            .filter((operator) => {
              if (operator.value === "=") operator.label = "equals";
              if (operator.value === ">") operator.label = "greater than";
              if (operator.value === "<") operator.label = "less than";
              return (
                operator.value === ">" ||
                operator.value === "<" ||
                operator.value === "="
              );
            })
            .map((operator) => ({
              ...operator,
              InputComponent: CustomFilter,
            })),
        ],
      };
    }

    const customFilterOperators = getGridStringOperators().map((operator) => ({
      ...operator,
      InputComponent: CustomFilter,
    }));

    return {
      ...col,
      filterOperators: customFilterOperators,
    };
  });

  const handleCreateUser = () => {
    setSelectedRow(null);
    setIsEdit(false);
    handleShowDetailsModal();
  };

  const handleShowDetailsModal = () => {
    setShowDetailsModal((current) => !current);
  };

  const handleShowConfirmModal = (value) => {
    setIsResetPassword(value);
    setShowConfirmModal((current) => !current);
  };

  return (
    <div className="user-accounts">
      {isInternalUser && <UserAccountsFilter />}
      <div className="card mt-4">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <h5 className="flex-shrink-1">List of User Accounts</h5>
            <div className="d-flex gap-0 flex-shrink-0">
              <div
                className="action-button align-self-center"
                onClick={handleCreateUser}
              >
                <div className="m-0">Add New User</div>
              </div>
            </div>
          </div>

          <div
            className="cstm-mui-datagrid"
            style={{ height: 500, width: "100%" }}
          >
            <DataGridPro
              loading={isFetchingUser}
              rows={users}
              columns={columnsWithOperators}
              slotProps={{
                filterPanel: {
                  sx: { maxWidth: "calc(90vw - 24px)" },
                },
              }}
              getRowId={(row) => row?.id}
              onColumnOrderChange={(c) => {
                setColumnsOrder((prevCols) => {
                  const newCols = [...prevCols];
                  newCols.splice(c.oldIndex, 1);
                  newCols.splice(c.targetIndex, 0, c.column);
                  return newCols;
                });
              }}
              initialState={{
                ...columns.initialState,
                columns: columns.initialState?.columns,
              }}
              localeText={convertGridColumnMenu(language)}
              apiRef={apiRef}
              rowsLoadingMode={"server"}
              hideFooter
            />
          </div>
        </div>
      </div>
      <ModalBox
        show={showDetailsModal}
        onHide={handleShowDetailsModal}
        content={
          <UserAccountModal
            isEdit={isEdit}
            isAdmin={isAdmin}
            closeModal={handleShowDetailsModal}
            current={selectedRow}
          />
        }
      />
      <ModalBox
        show={showConfirmModal}
        onHide={handleShowConfirmModal}
        content={
          <ConfirmModal
            isResetPassword={isResetPassword}
            closeModal={handleShowConfirmModal}
            current={selectedRow}
          />
        }
      />
    </div>
  );
};

export default memo(UserAccounts);
