import { MDBSwitch } from "mdb-react-ui-kit";
import React, { memo } from "react";

const SwitchDoubleLabels = ({ leftLabel, rightLabel, ...rest }) => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <span className="filter_label me-3">{leftLabel}</span>
      <MDBSwitch className="custom-toggle my-auto" {...rest} />
      <span className="filter_label ms-2">{rightLabel}</span>
    </div>
  );
};

export default memo(SwitchDoubleLabels);
