import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import "./Settings.scss";
import { UserContext } from "context/UserContext";
import { updateUserProfile } from "services/FetchApiData";
// import { getBase64 } from "helpers/ReusableFunctions";
// import ActionSvg from "../../assets/svg/action-table.svg";
// import PreviewDeleteSvg from "../../assets/svg/preview-delete.svg";
const Profile = () => {
  const { loggedInUserData } = useContext(UserContext);
  const [formData, setFormData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  // const [profilePreview, setProfilePreview] = useState(null);
  // const profileInputRef = useRef(null);

  useEffect(() => {
    setFormData({
      first_name: loggedInUserData?.profile?.first_name,
      last_name: loggedInUserData?.profile?.last_name,
      email: loggedInUserData?.profile?.email,
      phone_number: loggedInUserData?.profile?.phone_number,
      title: loggedInUserData?.profile?.title,
    });
  }, [
    loggedInUserData?.profile?.first_name,
    loggedInUserData?.profile?.last_name,
    loggedInUserData?.profile?.email,
    loggedInUserData?.profile?.phone_number,
    loggedInUserData?.profile?.title,
  ]);

  const handleEditClick = () => {
    setIsEditMode(true);
  };
  const handleInputChange = (e) => {
    if (isEditMode) {
      const { name, value } = e.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  const handleCancelClick = () => {
    setIsEditMode(false);
    // resetProfileImageData();
  };
  // const resetProfileImageData = () => {
  //   profilePreview && setProfilePreview(null);
  //   setProfileDetails((prev) => ({ ...prev, image: null }));
  //   if (profileInputRef.current.value) profileInputRef.current.value = null;
  // };

  // const handleUpload = () => {
  //   if (profileInputRef.current.value) profileInputRef.current.value = null;
  //   profileInputRef.current.click();
  // };
  // const handlePreviewDelete = () => {
  //   resetProfileImageData();
  // };
  // const handleImageInputChange = async (e) => {
  //   const uploadedFile = e.target.files[0];
  //   if (uploadedFile) {
  //     if (uploadedFile.name.match(/\.(png|jpg|jpeg|webp)$/)) {
  //       setProfileDetails((prev) => ({
  //         ...prev,
  //         image: uploadedFile,
  //       }));
  //       const profileImageUrl = await getBase64(uploadedFile);
  //       setProfilePreview(profileImageUrl);
  //       setErrors((prev) => ({
  //         ...prev,
  //         image: "",
  //       }));
  //     } else {
  //       setErrors((prev) => ({
  //         ...prev,
  //         image: "Please upload an image.",
  //       }));
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const newErrors = {};
    const itemsRequired = ["first_name", "last_name", "title"];
    itemsRequired.forEach((item) => {
      if (!formData[item]?.trim()) {
        newErrors[item] = "Required";
      }
    });
    setErrors(newErrors);
    if (!Object.keys(newErrors).length) {
      await updateUserProfile(formData);
    }
    setIsEditMode(false);
    setIsLoading(false);
  };

  return (
    <div className="profile-container">
      <Row>
        <Col md={3}></Col>
        <Col md={6} className="mt-4 card p-4">
          <form onSubmit={handleSubmit}>
            <Row>
              {/* <Col
                md={5}
                className="d-flex flex-column align-items-center gap-1"
              >
                <div>
                  {profilePreview ? (
                    <>
                      <MDBCardImage
                        src={profilePreview}
                        alt="Generic placeholder image"
                        className="mt-1 img-thumbnail object-cover"
                        fluid
                        style={{ width: "150px", height: "150px" }}
                      />
                      <div
                        className="d-flex gap-1 m-0 p-1 justify-content-center align-items-center"
                        style={{ maxWidth: "150px" }}
                      >
                        <p className="text-black fw-4 m-1 text fw-semibold text-truncate">
                          {profileDetails.image?.name}
                        </p>
                        <img
                          src={PreviewDeleteSvg}
                          alt="delete"
                          height="18px"
                          width="18px"
                          className="cursor-pointer"
                          onClick={handlePreviewDelete}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="profile-image-placeholder img-fluid mt-1 mb-2 img-thumbnail">
                      {loggedInUserData?.email
                        ? `${loggedInUserData?.email.split("@")[0][0]}${
                            loggedInUserData?.email.split("@")[1][0]
                          }`
                        : ""}
                    </div>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    ref={profileInputRef}
                    className="d-none"
                    onChange={handleImageInputChange}
                  />
                  {isEditMode ? (
                    <div
                      className={`${
                        profilePreview ? "mt-2" : "mt-5"
                      } mb-4 d-flex justify-content-center`}
                      onClick={handleUpload}
                    >
                      <div className="action-svg cursor-pointer pb-2">
                        <img src={ActionSvg} alt="action" />
                      </div>
                    </div>
                  ) : null}
                  {errors.image && (
                    <span className="error">{errors.image}</span>
                  )}
                </div>
              </Col> */}
              <Col md={12}>
                {errors.first_name && (
                  <div className="user-label">
                    <span className="error">{errors.first_name}</span>
                  </div>
                )}
                <input
                  type="text"
                  name="first_name"
                  value={formData?.first_name || ""}
                  onChange={handleInputChange}
                  placeholder="First name"
                  className="inputbox"
                  disabled={!isEditMode}
                />
                {errors.last_name && (
                  <div className="user-label">
                    <span className="error">{errors.last_name}</span>
                  </div>
                )}
                <input
                  type="text"
                  name="last_name"
                  value={formData?.last_name || ""}
                  onChange={handleInputChange}
                  placeholder="Last name"
                  className="inputbox"
                  disabled={!isEditMode}
                />
                {errors.email && (
                  <div className="user-label">
                    <span className="error">{errors.email}</span>
                  </div>
                )}
                <input
                  type="email"
                  name="email"
                  value={formData?.email || ""}
                  onChange={handleInputChange}
                  placeholder="Email"
                  className="inputbox mt-2"
                  disabled
                />
                {errors.phone_number && (
                  <div className="user-label">
                    <span className="error">{errors.phone_number}</span>
                  </div>
                )}
                <input
                  type="number"
                  name="phone_number"
                  value={formData?.phone_number || ""}
                  onChange={handleInputChange}
                  placeholder="Phone"
                  className="inputbox mt-2"
                  disabled={!isEditMode}
                />
                {errors.title && (
                  <div className="user-label">
                    <span className="error">{errors.tile}</span>
                  </div>
                )}
                <input
                  type="text"
                  name="title"
                  value={formData?.title || ""}
                  onChange={handleInputChange}
                  placeholder="Title"
                  className="inputbox mt-2"
                  disabled={!isEditMode}
                />
                <div className="d-flex justify-content-center justify-content-md-start">
                  {isEditMode ? (
                    <div className="mt-4 d-flex flex-wrap">
                      <button
                        className="action-button mb-4"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <div>
                            <Spinner
                              animation="border"
                              size="sm"
                              className="text-white"
                              style={{ width: "1rem" }}
                            />
                          </div>
                        ) : (
                          <div className="action-text">Save Profile</div>
                        )}
                      </button>
                      <div
                        className="action-button-outline"
                        onClick={() => handleCancelClick()}
                      >
                        <div className="action-text">Cancel</div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="action-button mt-4"
                      onClick={handleEditClick}
                    >
                      <div className="action-text">Edit Profile</div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </div>
  );
};
export default Profile;
