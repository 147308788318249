import React, { useCallback, useEffect, useRef, useState } from "react";
import TakeNote from "./TakeNote";
import NoteHistoryDetails from "./NoteHistoryDetails";
import { getCMNotes } from "services/FetchApiData";

const Notes = ({ data = {} }) => {
  const [notes, setNotes] = useState([]);
  const [noteSelected, setNoteSelected] = useState({});

  const noteHistoryDivRef = useRef(null);

  const fetchNotes = useCallback(async () => {
    if (!data?.candidate_id) return;
    try {
      const res = await getCMNotes(data?.candidate_id);
      setNotes(res?.data?.data?.candidates?.cm_notes);
    } catch (error) {
      console.error(error);
    }
  }, [data?.candidate_id]);

  const scrollToNoteHistory = () => {
    noteHistoryDivRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  useEffect(() => {
    scrollToNoteHistory();
  }, [notes]);

  return (
    <>
      <TakeNote
        notes={notes}
        setNotes={setNotes}
        candidateId={data?.candidateId || data?.candidate_id}
        noteSelected={noteSelected}
        setNoteSelected={setNoteSelected}
      />
      {notes?.length > 0 && (
        <NoteHistoryDetails
          notesDetail={notes}
          noteSelected={noteSelected}
          setNoteSelected={setNoteSelected}
          setNotes={setNotes}
          ref={noteHistoryDivRef}
        />
      )}
    </>
  );
};

export default Notes;
