import SelectUI from "components/Select/SelectUI";
import React, { memo, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import "./UserAccountModal.scss";

const UserAccountModal = ({
  isEdit = false,
  isAdmin = false,
  current = {},
  closeModal = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(current);
  const [errors, setErrors] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [selectedLanguage, setSelectedLanguage] = useState();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const newErrors = {};
    const itemsRequired = ["first_name", "last_name", "title"];
    itemsRequired.forEach((item) => {
      if (!formData[item]?.trim()) {
        newErrors[item] = "Required";
      }
    });
    setErrors(newErrors);
    if (!Object.keys(newErrors).length) {
      console.log("formData", formData);
    }

    setIsLoading(false);
  };

  return (
    <div className="user-account-modal">
      <h4>
        {isEdit
          ? `Edit User: ${current?.first_name} ${current?.last_name}`
          : "Create New User"}
      </h4>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col md={6} className="input-field">
            <div className="user-label">
              First Name
              {errors.first_name && (
                <span className="error">{errors.first_name}</span>
              )}
            </div>
            <input
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleInputChange}
            />
          </Col>
          <Col md={6} className="input-field">
            <div className="user-label">
              Last Name
              {errors.last_name && (
                <span className="error">{errors.last_name}</span>
              )}
            </div>
            <input
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleInputChange}
            />
          </Col>
          <Col md={6} className="input-field">
            <div className="user-label">
              Email Address
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
            <input
              readOnly={isEdit}
              type="text"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </Col>
          <Col md={6} className="input-field">
            <div className="user-label">
              Job Title
              {errors.title && <span className="error">{errors.title}</span>}
            </div>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
            />
          </Col>
          {isAdmin && (
            <Col md={6} className="input-field">
              <div className="user-label">
                User Type
                {errors.userType && (
                  <span className="error">{errors.userType}</span>
                )}
              </div>
              <SelectUI
                className="add-export-select"
                options={[]}
                selectedValue={() => {}}
                value={selectedLanguage}
              />
            </Col>
          )}

          {!isEdit && (
            <>
              <Col md={6} className="input-field">
                <div className="user-label">
                  User Access
                  {errors.userAccess && (
                    <span className="error">{errors.userAccess}</span>
                  )}
                </div>
                <SelectUI
                  className="add-export-select"
                  options={[]}
                  selectedValue={() => {}}
                  value={selectedLanguage}
                />
              </Col>
            </>
          )}
        </Row>
        <div className="user-card-button">
          <button type="submit" className="action-button" disabled={isLoading}>
            {isLoading ? (
              <div>
                <Spinner
                  animation="border"
                  size="sm"
                  className="text-white"
                  style={{ width: "1rem" }}
                />
              </div>
            ) : (
              <div className="m-0">Save</div>
            )}
          </button>
          <button
            type="button"
            className="action-button-outline"
            onClick={closeModal}
          >
            <div className="m-0">Cancel</div>
          </button>
        </div>
      </form>
    </div>
  );
};

export default memo(UserAccountModal);
