import {
  DataGridPro,
  getGridNumericOperators,
  getGridStringOperators,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import CustomFilter from "components/Reports/CustomFilter";
import { UserContext } from "context/UserContext";
import { convertGridColumnMenu } from "helpers/ReusableFunctions";
import React, { memo, useContext, useState } from "react";
import "./ReportPermissions.scss";
import ButtonUI from "components/UI/Button/ButtonUI";
import ModalBox from "components/Modal/ModalBox";
import ReportPermissionsModal from "./ReportPermissionsModal";
import ReportPermissionsFilter from "./ReportPermissionsFilter";
import { FilterContext } from "context/FilterContext";

const ReportPermissions = () => {
  const apiRef = useGridApiRef();
  const { isInternalUser } = useContext(FilterContext);
  const { language } = useContext(UserContext);

  const dummyReports = Array.from({ length: 200 })?.map((_value, index) => ({
    id: index + 1,
    report_name: "Internal Pipeline Report",
    user_type: "Client",
    user_name: "PayPay",
    access: "No",
  }));

  const sortableColumn = true;
  const pinnableColumn = true;

  const filterOperators = getGridStringOperators().filter(
    ({ value }) => !["isEmpty", "isNotEmpty", "isAnyOf"].includes(value)
  );

  const columns = [
    {
      field: "report_name",
      headerName: "Report Name",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "user_type",
      headerName: "User Type",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "user_name",
      headerName: "User Name",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "access",
      headerName: "Access (Y/N)",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "action_2",
      type: "actions",
      headerName: "",
      width: 200,
      renderCell: ({ row }) => (
        <ButtonUI
          outline="no-outline my-auto"
          title="Edit Access"
          onClick={() => {
            setSelectedRow(row);
            handleShowDetailsModal();
          }}
        />
      ),
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const [isLoadingTableData, setIsLoadingTableData] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [reportListData, setReportListData] = useState(dummyReports);
  const [columnsOrder, setColumnsOrder] = useState(columns);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const columnsWithOperators = columnsOrder.map((col) => {
    if (col.type === "number") {
      return {
        ...col,
        filterOperators: [
          ...getGridNumericOperators()
            .filter((operator) => {
              if (operator.value === "=") operator.label = "equals";
              if (operator.value === ">") operator.label = "greater than";
              if (operator.value === "<") operator.label = "less than";
              return (
                operator.value === ">" ||
                operator.value === "<" ||
                operator.value === "="
              );
            })
            .map((operator) => ({
              ...operator,
              InputComponent: CustomFilter,
            })),
        ],
      };
    }

    const customFilterOperators = getGridStringOperators().map((operator) => ({
      ...operator,
      InputComponent: CustomFilter,
    }));

    return {
      ...col,
      filterOperators: customFilterOperators,
    };
  });

  const handleShowDetailsModal = () => {
    setShowDetailsModal((current) => !current);
  };

  return (
    <>
      <ReportPermissionsFilter isInternalUser={isInternalUser} />
      <div className="card mt-4">
        <div className="card-body">
          <h5 className="flex-shrink-1">List of Reports & Permissions</h5>
          <div
            className="cstm-mui-datagrid"
            style={{ height: 500, width: "100%" }}
          >
            <DataGridPro
              loading={isLoadingTableData}
              rows={reportListData}
              columns={columnsWithOperators}
              slotProps={{
                filterPanel: {
                  sx: { maxWidth: "calc(90vw - 24px)" },
                },
              }}
              getRowId={(row) => row?.id}
              onColumnOrderChange={(c) => {
                setColumnsOrder((prevCols) => {
                  const newCols = [...prevCols];
                  newCols.splice(c.oldIndex, 1);
                  newCols.splice(c.targetIndex, 0, c.column);
                  return newCols;
                });
              }}
              initialState={{
                ...columns.initialState,
                columns: columns.initialState?.columns,
              }}
              localeText={convertGridColumnMenu(language)}
              apiRef={apiRef}
              rowsLoadingMode={"server"}
              hideFooter
            />
          </div>
        </div>
      </div>
      <ModalBox
        show={showDetailsModal}
        onHide={handleShowDetailsModal}
        content={
          apiRef?.current?.state && (
            <ReportPermissionsModal
              closeModal={handleShowDetailsModal}
              current={selectedRow}
              apiRef={apiRef}
            />
          )
        }
      />
    </>
  );
};
export default memo(ReportPermissions);
