import SelectUI from "components/Select/SelectUI";
import ButtonUI from "components/UI/Button/ButtonUI";
import { FilterContext } from "context/FilterContext";
import React, { memo, useContext } from "react";
import { Col, Row } from "react-bootstrap";

const UserAccountsFilter = () => {
  const { setFilterStore, dropdownData } = useContext(FilterContext);

  const selectedFilterValue = (e, entryName) => {
    setFilterStore((prevFilterStore) => ({
      ...prevFilterStore,
      [entryName]: e,
    }));
  };

  return (
    <div className="user-accounts-filter">
      <Row className="align-items-md-end">
        <Col md={3}>
          <div className="select-category">
            <div className="category-block d-flex justify-content-between">
              <h3>Filter By Organization</h3>
            </div>
            <div>
              <SelectUI
                options={dropdownData?.Organizations}
                isClearable
                selectedValue={selectedFilterValue}
                entryName="Organization"
                defaultValue={dropdownData?.Organizations[0]}
              />
            </div>
          </div>
        </Col>
        <Col md="auto" className="d-flex justify-content-center gap-3">
          <ButtonUI outline="no-outline" title="Apply" />
          <ButtonUI title="Clear All" />
        </Col>
      </Row>
    </div>
  );
};

export default memo(UserAccountsFilter);
