import React, { createContext, useState } from "react";

const initialAppContextValue = {
  candidatesGroupedByBM: [],
  setCandidatesGroupedByBM: () => {},
  candidatesGroupedByCM: [],
  setCandidatesGroupedByCM: () => {},
  candidatesList: [],
  setCandidatesList: () => {},
  meetingsConversionMetricsData: {},
  setMeetingsConversionMetricsData: () => {},
};

export const AppContext = createContext(initialAppContextValue);

export const AppProvider = ({ children }) => {
  const [candidatesGroupedByCM, setCandidatesGroupedByCM] = useState([]);
  const [candidatesGroupedByBM, setCandidatesGroupedByBM] = useState([]);
  const [meetingsConversionMetricsData, setMeetingsConversionMetricsData] =
    useState();

  return (
    <AppContext.Provider
      value={{
        candidatesGroupedByBM,
        setCandidatesGroupedByBM,
        candidatesGroupedByCM,
        setCandidatesGroupedByCM,
        meetingsConversionMetricsData,
        setMeetingsConversionMetricsData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
