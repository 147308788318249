import {
  DataGridPro,
  getGridNumericOperators,
  getGridStringOperators,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import ProfileModalContent from "components/CandidateProfileModalContent/ProfileModalContent";
import FilterNav from "components/FilterNav/FilterNav";
import ModalBox from "components/Modal/ModalBox";
import PageTitle from "components/PageTitle/PageTitle";
import CustomFilter from "components/Reports/CustomFilter";
import { FilterContext } from "context/FilterContext";
import { UserContext } from "context/UserContext";
import {
  haveLeaderboardAccess,
  maximumExportPaging,
  PAGES,
  PitchDeclinedReasonChoices,
  PitchResponseChoices,
} from "helpers/Constant";
import {
  calculateAge,
  calculateStatsForMeetingConversionReport,
  convertGridColumnMenu,
  convertStageCodeToName,
  customStageColumnSorting,
  delay,
  getColumnsAfterChangeOrder,
  getCurrentPageAllFiltersFromFilterStore,
  groupingCandidatesByBM,
  groupingCandidatesByCM,
  reformatString,
} from "helpers/ReusableFunctions";
import { isEmpty } from "lodash";
import ExportModal from "pages/PipelineReport/components/ExportModal";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { getMeetingConversionReport } from "services/FetchApiData";
import { AppContext } from "context/AppContext";
import MeetingConversionReportNumbers from "components/MeetingConversionReportNumbers/MeetingConversionReportNumbers";
import MeetingsConversionGraphs from "./components/MeetingsConversionGraphs";

const initialHiddenColumns = {
  id: false,
  birthday: false,
  response_reason: false,
};

const sortableColumn = true;
const pinnableColumn = true;

const MeetingConversionReport = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const apiRef = useGridApiRef();
  const { language, userGroup, loggedInUserData } = useContext(UserContext);
  const {
    filterStore,
    setLastAppliedFilterstore,
    isInternalUser,
    isDisableApplyButton,
    setIsDisableApplyButton,
    loading,
    setLoading,
    applyFilterFlags: { applyFilterInMeetingConversionReport },
  } = useContext(FilterContext);
  const {
    setCandidatesGroupedByBM,
    setCandidatesGroupedByCM,
    setMeetingsConversionMetricsData,
  } = useContext(AppContext);

  const filterOperators = getGridStringOperators().filter(
    ({ value }) => !["isEmpty", "isNotEmpty", "isAnyOf"].includes(value)
  );

  const [initialRenderFlag, setInitialRenderFlag] = useState(true);
  const [showExportModal, setShowExportModal] = useState(false);
  const [candidatesData, setCandidatesData] = useState([]);
  const [meetingConversionData, setMeetingConversionData] = useState();
  const [lineData, setLineData] = useState();
  const [pagination, setPagination] = useState({ limit: 100, offset: 0 });
  const [selectedRow, setSelectedRow] = useState(null);
  const [leaderboardAccessIds, setLeaderboardAccessIds] = useState({
    cm_ids: [],
    bm_ids: [],
  });
  const [showProfileModal, setShowProfileModal] = useState(false);

  const renderSocialLinksActions = (params) => {
    return (
      <Link to={params.value} target="_blank" rel="noreferrer">
        {params.value}
      </Link>
    );
  };

  const japaneseName = [
    {
      field: "kanji_last",
      headerName: t("performanceReportText.lastNameJ"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "kanji_first",
      headerName: t("performanceReportText.firstNameJ"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
  ];

  const englishName = [
    {
      field: "first_name",
      headerName: t("performanceReportText.firstName"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "last_name",
      headerName: t("performanceReportText.lastName"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
  ];

  const columns = [
    {
      field: "id",
      headerName: t("performanceReportText.id"),
      width: 30,
    },
    {
      field: "candidate_manager",
      headerName: "Candidate Manager",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "business_manager",
      headerName: "Business Manager",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "client_name",
      headerName: "Client",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "app_position_name",
      headerName: "Position",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },

    ...(language === "ja" ? japaneseName : englishName),
    {
      field: "current_stage",
      type: "string",
      headerName: "Stage",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      valueGetter: ({ row }) => {
        return convertStageCodeToName(
          row?.current_stage,
          row?.app_position?.pitch_response.response,
          "New Candidate"
        );
      },
      sortComparator: customStageColumnSorting,
    },
    {
      field: "current_employer",
      headerName: t("performanceReportText.employer"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "current_title",
      headerName: t("performanceReportText.title"),
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "initial_response",
      headerName: t("performanceReportText.initialResponse"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "response_reason",
      headerName: "Reason",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "role",
      headerName: "Role",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "focus",
      headerName: "Focus",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "salary",
      headerName: t("performanceReportText.salary"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      type: "number",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "salary_breakdown",
      headerName: t("performanceReportText.salaryBreakdown"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "english",
      headerName: t("performanceReportText.english"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      type: "number",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "japanese",
      headerName: t("performanceReportText.japanese"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      type: "number",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "outreach_url",
      headerName: "Outreach",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "zoho_url",
      headerName: "ZOHO",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "trello_url",
      headerName: "Trello",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "linkedin_url",
      headerName: t("performanceReportText.linkedinUrl"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      renderCell: renderSocialLinksActions,
    },
    {
      field: "twitter_url",
      headerName: t("performanceReportText.twitterUrl"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      renderCell: renderSocialLinksActions,
    },
    {
      field: "facebook_url",
      headerName: t("performanceReportText.facebookUrl"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      renderCell: renderSocialLinksActions,
    },
    {
      field: "github_url",
      headerName: t("performanceReportText.githubUrl"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      renderCell: renderSocialLinksActions,
    },
    {
      field: "birthday",
      headerName: "Birth Date",
      // headerName: performanceReportText.id[language],
      // width: 30,
    },
    {
      field: "age",
      headerName: t("performanceReportText.age"),
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "education_history",
      headerName: t("performanceReportText.educationHistory"),
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "work_history",
      headerName: t("performanceReportText.workHistory"),
      width: 500,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "text",
      headerName: "Notes",
      width: 500,
      sortable: sortableColumn,
      pinnableColumn: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "other_applications",
      headerName: "Other Applications",
      width: 500,
      sortable: sortableColumn,
      pinnableColumn: pinnableColumn,
      filterOperators: filterOperators,
    },
  ];

  const [columnsOrder, setColumnsOrder] = useState(
    columns?.filter((col) => !initialHiddenColumns[col.field])
  );

  const [hiddenColumns, setHiddenColumns] = useState(
    Object.keys(initialHiddenColumns)
  );

  const columnsWithOperators = columnsOrder.map((col) => {
    if (col.type === "number") {
      return {
        ...col,
        filterOperators: [
          ...getGridNumericOperators()
            .filter((operator) => {
              if (operator.value === "=") operator.label = "equals";
              if (operator.value === ">") operator.label = "greater than";
              if (operator.value === "<") operator.label = "less than";
              return (
                operator.value === ">" ||
                operator.value === "<" ||
                operator.value === "="
              );
            })
            .map((operator) => ({
              ...operator,
              InputComponent: CustomFilter,
            })),
        ],
      };
    }

    const customFilterOperators = getGridStringOperators().map((operator) => ({
      ...operator,
      InputComponent: CustomFilter,
    }));

    return {
      ...col,
      filterOperators: customFilterOperators,
    };
  });

  const fetchMeetingConversionReportData = async (
    paginationValues = pagination
  ) => {
    try {
      setIsDisableApplyButton(true);
      setLoading(true);
      const response = await getMeetingConversionReport(
        getCurrentPageAllFiltersFromFilterStore(
          filterStore,
          isInternalUser,
          PAGES.MEETING_CONVERSION_REPORT
        ),
        paginationValues,
        language
      );
      const { metricsData, lineGraphData } =
        calculateStatsForMeetingConversionReport(response);
      setMeetingConversionData(metricsData?.[0]);
      setMeetingsConversionMetricsData(metricsData?.[0]);
      setLineData(lineGraphData);
      const candidateListData =
        response.data.data.reports.meeting_conversion_candidates_list;
      setCandidatesData([
        ...(paginationValues?.offset === 0 ? [] : candidatesData),
        ...candidateListData?.data?.map((user, index) => {
          if (isInternalUser) {
            setLeaderboardAccessIds((prev) => ({
              cm_ids: [
                ...prev.cm_ids,
                user.app_position?.candidate_manager?.id,
              ],
              bm_ids: [...prev.bm_ids, user.app_position?.business_manager?.id],
            }));
          }

          return {
            id: index + 1,
            age: +calculateAge(user.birthday),
            initial_response: reformatString(
              PitchResponseChoices[user.app_position?.pitch_response.response]
            ),
            response_reason: reformatString(
              PitchDeclinedReasonChoices[
                user.app_position?.pitch_response.reason
              ]
            ),
            candidate_manager: `${
              user.app_position?.candidate_manager?.first_name ?? ""
            } ${user.app_position?.candidate_manager?.last_name ?? ""}`,
            business_manager: `${
              user?.app_position?.business_manager?.first_name ?? ""
            } ${user.app_position?.business_manager?.last_name ?? ""}`,
            ...(Array.isArray(user.app_position?.stage_history) &&
            user.app_position?.stage_history.some(
              (data) => data.stage === user.app_position?.current_stage
            )
              ? user.app_position?.stage_history
                  .filter(
                    (data) => data.stage === user.app_position?.current_stage
                  )
                  .map((s, i, arr) => ({
                    text: Array.isArray(s.comments)
                      ? [...s?.comments].sort(
                          (a, b) =>
                            new Date(b.created_at).getMilliseconds() -
                            new Date(a.created_at).getMilliseconds()
                        )[0].text
                      : "",
                    other_applications: s?.other_applications,
                  }))[0]
              : {
                  text: "",
                  other_applications: "",
                }),
            client_name: user.app_position?.client_name,
            current_stage: user.app_position?.current_stage,
            elapsed_time: `${user?.time_diff?.days}D ${user?.time_diff?.hours}H ${user?.time_diff?.minutes}M`,
            focus: user.app_position?.focus,
            app_position_name: user.app_position?.position_name,
            role: user.app_position?.role,
            last_activity_date: new Date(user.last_activity_date),
            last_activity_type: user.last_activity_type,
            ...user,
          };
        }),
      ]);
      setCandidatesGroupedByCM(groupingCandidatesByCM(candidateListData?.data));
      setCandidatesGroupedByBM(groupingCandidatesByBM(candidateListData?.data));
      setLastAppliedFilterstore(filterStore);
      setPagination({
        ...paginationValues,
        count: candidateListData?.count || 0,
        hasMore: candidateListData?.has_more,
      });
    } catch (error) {
      // Handle error case
      console.log(error);
    } finally {
      setIsDisableApplyButton(false);
      setLoading(false);
    }
  };

  const handleShowExportModal = () => {
    setShowExportModal((current) => !current);
  };

  const handleShowProfileModal = () => {
    setShowProfileModal((current) => !current);
  };

  const handlePaginationModelChange = async ({ pageSize, page }) => {
    let isPageSizeChanged = pagination?.page >= page;
    let newPage = isPageSizeChanged ? 0 : page;
    const newPaging = {
      ...pagination,
      page: newPage,
      limit: pageSize,
      offset: isPageSizeChanged ? 0 : pageSize * page,
    };
    apiRef.current.setPage(newPage);
    setPagination(newPaging);
    await fetchMeetingConversionReportData(newPaging);
  };

  const handleExport = async () => {
    await handlePaginationModelChange(maximumExportPaging);
    await apiRef.current.setPaginationModel(maximumExportPaging);
    await delay(1000);
  };

  useEffect(() => {
    setInitialRenderFlag(false);
  }, []);

  useEffect(() => {
    if (!initialRenderFlag) {
      setColumnsOrder((prev) => {
        const temp = prev.map((item) =>
          getColumnsAfterChangeOrder(item, columns)
        );
        return temp;
      });
    }
    // eslint-disable-next-line
  }, [language]);

  useEffect(() => {
    if (initialRenderFlag) return;
    if (pagination?.offset === 0) {
      fetchMeetingConversionReportData();
    } else {
      apiRef.current.setPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    initialRenderFlag,
    applyFilterInMeetingConversionReport,
    filterStore.startDate,
    filterStore.endDate,
    filterStore.count,
    filterStore.unit,
  ]);

  return (
    <div>
      <PageTitle
        title="Meeting Conversion Report"
        back={t("performanceReportText.backToList")}
        allowLeaderboardAccess={
          haveLeaderboardAccess.includes(location.pathname) && isInternalUser
        }
        isDisabledToNavigateLeaderboard={
          isEmpty(candidatesData) || isDisableApplyButton
        }
        navigateLeaderboardFrom={location.pathname}
        leaderboardAccessIds={{
          cm_ids: [
            ...Array.from(
              new Set([...leaderboardAccessIds["cm_ids"]].filter((id) => !!id))
            ),
          ],
          bm_ids: [
            ...Array.from(
              new Set([...leaderboardAccessIds["bm_ids"]].filter((id) => !!id))
            ),
          ],
        }}
      />
      <FilterNav />
      <MeetingConversionReportNumbers
        meetingConversionData={meetingConversionData}
      />
      <MeetingsConversionGraphs lineData={lineData} />
      <div className="card mt-4">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <h5 className="flex-shrink-1">List of Candidate Managers</h5>
            {(userGroup.includes("external") ||
              (userGroup.includes("internal") &&
                loggedInUserData.is_admin)) && (
              <div
                className="action-button align-self-center"
                onClick={handleShowExportModal}
              >
                <div className="m-0">{t("performanceReportText.export")}</div>
              </div>
            )}
          </div>
          <div
            className="cstm-mui-datagrid"
            style={{ height: 500, width: "100%" }}
          >
            <DataGridPro
              loading={loading}
              rows={candidatesData}
              rowCount={pagination?.count}
              pagination
              pageSizeOptions={[50, 100, 500, 1000]}
              onPaginationModelChange={handlePaginationModelChange}
              columns={columnsWithOperators}
              slotProps={{
                filterPanel: {
                  sx: { maxWidth: "calc(90vw - 24px)" },
                },
                pagination: {
                  backIconButtonProps: {
                    disabled: loading || !pagination?.offset,
                  },
                  nextIconButtonProps: {
                    disabled: loading || !pagination?.hasMore,
                  },
                },
              }}
              onColumnOrderChange={(c) => {
                setColumnsOrder((prevCols) => {
                  const newCols = [...prevCols];
                  newCols.splice(c.oldIndex, 1);
                  newCols.splice(c.targetIndex, 0, c.column);
                  return newCols;
                });
              }}
              initialState={{
                ...columns.initialState,
                sorting: {
                  sortModel: [{ field: "last_activity_type", sort: "asc" }],
                },
                columns: {
                  ...columns.initialState?.columns,
                  columnVisibilityModel: initialHiddenColumns,
                },
                pagination: { paginationModel: { pageSize: 100 } },
              }}
              onRowClick={(params) => {
                setSelectedRow(params.row);
                handleShowProfileModal();
              }}
              localeText={convertGridColumnMenu(language)}
              apiRef={apiRef}
              onColumnVisibilityModelChange={(hideColumns) => {
                const hideColsName = Object.entries(hideColumns)
                  .filter(([key, value]) => value === false)
                  ?.map((col) => col[0]);
                setHiddenColumns(hideColsName);
              }}
              rowsLoadingMode={"server"}
            />
          </div>
        </div>
      </div>
      <ModalBox
        show={showProfileModal}
        onHide={handleShowProfileModal}
        content={
          apiRef?.current?.state && (
            <ProfileModalContent
              closeModal={handleShowProfileModal}
              current={selectedRow}
              title={t("cardViewText.profileView")}
              apiRef={apiRef}
            />
          )
        }
      />
      <ModalBox
        show={showExportModal}
        onHide={handleShowExportModal}
        title="Export File"
        content={
          <ExportModal
            setShowModal={setShowExportModal}
            columns={columnsOrder}
            fileName="Meeting Conversion Report"
            apiRef={apiRef}
            hiddenColumns={hiddenColumns}
            onExport={handleExport}
          />
        }
      />
    </div>
  );
};

export default MeetingConversionReport;
