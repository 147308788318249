import SelectUI from "components/Select/SelectUI";
import ButtonUI from "components/UI/Button/ButtonUI";
import { FilterContext } from "context/FilterContext";
import { selectOptionConverter } from "helpers/ReusableFunctions";
import React, { memo, useContext } from "react";
import { Col, Row } from "react-bootstrap";

const ReportPermissionsFilter = ({ isInternalUser = false }) => {
  const { setFilterStore, dropdownData } = useContext(FilterContext);

  const fieldOptions = [
    {
      name: "UserType",
      label: "Select User Type",
      option: dropdownData?.UserType,
      disabled: !isInternalUser,
    },
    ...(isInternalUser
      ? [
          {
            name: "Organization",
            label: "Select Organization",
            option: dropdownData?.UserType,
          },
        ]
      : []),
    {
      name: "Report",
      label: "Select Report",
      option: dropdownData?.UserType,
    },
  ];

  const allSelectableFieldNames = ["UserType", "Organization", "Report"];

  const selectedFilterValue = (e, entryName) => {
    setFilterStore((prevFilterStore) => ({
      ...prevFilterStore,
      [entryName]: e,
    }));
  };

  return (
    <div>
      <Row className="align-items-md-end">
        {fieldOptions?.map((entry, i) => (
          <Col key={i} md={3}>
            <div className="select-category">
              <div className="category-block d-flex justify-content-between">
                <h3>{entry.label}</h3>
              </div>
              <div>
                <SelectUI
                  options={selectOptionConverter(entry.option)}
                  isMulti
                  isClearable
                  selectedValue={selectedFilterValue}
                  entryName={entry?.name}
                  isRenderInputCheckbox={allSelectableFieldNames.includes(
                    entry?.name
                  )}
                  allowSelectAll={allSelectableFieldNames.includes(entry?.name)}
                  isDisabled={entry?.disabled || false}
                />
              </div>
            </div>
          </Col>
        ))}
        <Col md="auto">
          <ButtonUI outline="no-outline" title="View" />
        </Col>
      </Row>
    </div>
  );
};

export default memo(ReportPermissionsFilter);
