import React, { memo, useState } from "react";
import { Spinner } from "react-bootstrap";
import "./ConfirmModal.scss";

const ConfirmModal = ({
  isResetPassword = false,
  current = {},
  closeModal = () => {},
}) => {
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="confirm-modal">
      <h4>
        {isResetPassword ? "Reset Password" : "Account Access"}:{" "}
        {current?.user_name}
      </h4>
      <div className="d-flex gap-5 mb-4">
        <p className="profile_subsection-dim_title">
          User Type: <span>{current?.user_type}</span>
        </p>
        <p className="profile_subsection-dim_title">
          Email: <span>{current?.email}</span>
        </p>
        <p className="profile_subsection-dim_title">
          Job Title: <span>{current?.job_title}</span>
        </p>
      </div>
      <p className="profile_subsection-dim_title mb-0">
        {isResetPassword
          ? "Are you sure you want to reset this user's password?"
          : "Are you sure you want to revoke access to this user?"}
      </p>
      <div className="user-card-button">
        <button type="submit" className="action-button" disabled={isLoading}>
          {isLoading ? (
            <div>
              <Spinner
                animation="border"
                size="sm"
                className="text-white"
                style={{ width: "1rem" }}
              />
            </div>
          ) : (
            <div className="m-0">Yes</div>
          )}
        </button>
        <button
          type="button"
          className="action-button-outline"
          onClick={closeModal}
        >
          <div className="m-0">Cancel</div>
        </button>
      </div>
    </div>
  );
};

export default memo(ConfirmModal);
