import Submenu from "components/SubMenu/Submenu";
import React, { useContext, useEffect, useState } from "react";
import ReportPermissions from "./components/ReportPermissions";
import UserAccounts from "./components/UserAccounts";
import "./AdminPanel.scss";
import { getUsers } from "services/FetchApiData";
import { UserContext } from "context/UserContext";
import { FilterContext } from "context/FilterContext";

const TABS = {
  REPORT_PERMISSIONS: "Report Permissions",
  USER_ACCOUNTS: "User Accounts",
};

const AdminPanel = () => {
  const { language, loggedInUserData } = useContext(UserContext);
  const { isInternalUser } = useContext(FilterContext);

  const [initialRenderFlag, setInitialRenderFlag] = useState(true);
  const [basicActive, setBasicActive] = useState(TABS.REPORT_PERMISSIONS);
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({ limit: 25, nextToken: null });
  const [isFetchingUser, setIsFetchingUser] = useState(false);

  useEffect(() => {
    setInitialRenderFlag(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!initialRenderFlag) {
      fetchUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialRenderFlag]);

  const fetchUsers = async (clientId) => {
    try {
      setIsFetchingUser(true);
      const response = await getUsers(clientId, pagination);
      setUsers(response?.data?.data?.admin?.list_users?.data);
      setPagination({
        ...pagination,
        nextToken: response?.data?.data?.admin?.list_users?.next_token,
      });
    } catch (error) {
      // Handle error case
      console.log(error);
    } finally {
      setIsFetchingUser(false);
    }
  };

  const tabs = [
    {
      title: TABS.REPORT_PERMISSIONS,
      content: <ReportPermissions />,
    },
    {
      title: TABS.USER_ACCOUNTS,
      content: (
        <UserAccounts
          language={language}
          isInternalUser={isInternalUser}
          isAdmin={loggedInUserData?.is_admin}
          isFetchingUser={isFetchingUser}
          users={users}
        />
      ),
    },
  ];

  return (
    <>
      <h4>Admin Panel</h4>
      <Submenu
        tabs={tabs}
        basicActive={basicActive}
        setBasicActive={setBasicActive}
      />
    </>
  );
};

export default AdminPanel;
